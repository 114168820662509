// main: ../main.scss
/* ============================================== */
/* TEAM SECTION STYLES
/* ============================================== */
#team {
    background: url('../images/girl-with-phone-art.png') no-repeat 90% 55%;
}


/* ============================================== */
/* WHY SECTION STYLES
/* ============================================== */
#why {
    &::after {
        left: 25%;
    }
}
