// main: ../main.scss
/*********************
FONT FACE (IN YOUR FACE)
*********************/

// font stacks
$sans-serif:        'Helvetica Neue', Helvetica, Arial, sans-serif;
$serif:             'Georgia', Cambria, 'Times New Roman', Times, serif;

/*
 i imported this one in the functions file so bones would look sweet.
 don't forget to remove it for your site.
*/
$lato:              'Lato', $sans-serif;

/*
some nice typographical defaults
more here: http://www.newnet-soft.com/blog/csstypography
*/

$h1-plus: 3.2rem;
$h1: 2.2rem;
$h2: 1.8rem;
$h3: 1.4rem;
$h4: 1.2rem;
$h5: 1.1rem;
$regular-text: 1rem;
$small-text: .9rem;
$very-small-text: .8rem;
$tiny-text: .75rem;

/* ==================================== */
/* H1, H2, H3, H4, H5, H6 STYLES
/* ==================================== */

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    margin-bottom: 20px;
    font-weight: 900;
    line-height: 1.2;
    text-rendering: optimizelegibility;

    a {
        outline: none;
        text-decoration: none;
    }
}

h1, .h1 {
    font-size: $h1;
}

h2, .h2 {
    font-size: $h2;
}

h3, .h3 {
    font-size: $h3;
}

h4, .h4 {
    font-size: $h4;
}

h5, .h5, h6, .h6 {
    font-size: $h5;
}

p {
    display: block;
    margin: 5px 0;
    font-weight: 300;
    line-height: 1.6;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word; //non-standart css for webkit
    // cleaning up the ragged lines and breaks
    -webkit-hyphens: manual;
    -moz-hyphens: manual;
     hyphens: manual;
    // sets a minimum number of characters before and after the break
    -webkit-hyphenate-before: 2;
    -webkit-hyphenate-after: 3;
    hyphenate-lines: 3;

    a {
        outline: none;
        color: $main !important; //sass-lint:disable-line no-important

        &:hover {
            text-decoration: underline;
        }
    }
}

blockquote {
    display: block;
    position: relative;
    margin: 15px 0;
    padding: .5rem 10px;
    border-left: 6px solid $gray;
    background: $gray;

    &::before {
        position: absolute;
        top: 8px;
        left: 8px;
        width: 25px;
        height: 25px;
        background: url('../images/quotes.svg') no-repeat center center;
        content: '';
    }

    p {
        display: block;
        margin: 0;
        text-indent: 40px;

        &::first-line {
            line-height: 200%;
        }
    }
}

a {
    @include transition(all .25s ease);
    display: inline-block;
    outline: none;
    color: $link-color;
    text-decoration: none;

    &:active, &:visited, &:focus, &:hover {
        outline: none !important; // sass-lint:disable-line no-important
    }

    &:visited {
        color: $link-color;
    }

    &:hover, &:focus {
        color: $link-hover;
    }

    /* mobile tap color */
    &:link {
        -webkit-tap-highlight-color: rgba(0, 0, 0, .3);
    }
}

ul, ol, li {
    font-weight: 300;
}
