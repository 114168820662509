// main: ../main.scss
/*********************
TRANSITION
*********************/

@mixin transition($transition...) {
    // defining prefixes so we can use them in mixins below
    $prefixes: ('-webkit-', '-moz-', '-ms-' );
    @each $prefix in $prefixes {
        #{$prefix}transition: $transition;
    }
    transition: $transition;
}

/*********************
TRANSFORM
*********************/

@mixin transform($transform...) {
    // defining prefixes so we can use them in mixins below
    $prefixes: ('-webkit-', '-moz-', '-ms-' );
    @each $prefix in $prefixes {
        #{$prefix}transform: $transform;
    }
    transform: $transform;
}
@mixin transform-origin($transform...) {
    // defining prefixes so we can use them in mixins below
    $prefixes:      ('-webkit-', '-moz-', '-ms-' );
    @each $prefix in $prefixes {
        #{$prefix}transform-origin: $transform;
    }
    transform-origin: $transform;
}

/*********************
CSS3 GRADIENTS
Be careful with these since they can
really slow down your CSS. Don't overdo it.
*********************/

/* @include css-gradient(#dfdfdf,#f8f8f8); */
@mixin css-linear-gradient($orientation: top, $from-color: #dfdfdf, $from-point: 0%, $to-color: #f8f8f8, $to-point: 100%) {
    background: $from-color;
    background: -webkit-linear-gradient($orientation, $from-color $from-point,$to-color $to-point);
    @if ($orientation == -45deg) {
        $orientation: 315deg;
        background: -webkit-linear-gradient(315deg, $from-color $from-point,$to-color $to-point);
        background: -o-linear-gradient(135deg, $from-color $from-point,$to-color $to-point);
        background: linear-gradient(135deg, $from-color $from-point,$to-color $to-point);
    } @else {
        background: -o-linear-gradient($orientation, $from-color $from-point,$to-color $to-point);
        background: linear-gradient($orientation, $from-color $from-point,$to-color $to-point);
    }
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$from-color, endColorstr=$to-color,GradientType=1 );
}

@mixin css-radial-gradient($from: #dfdfdf, $to: #f8f8f8) {
    background: #ffcc33;
    background: -webkit-radial-gradient(center, ellipse cover, #ffcc33 0%,#66cc66 74%);
    background: -webkit-radial-gradient(center, ellipse, #ffcc33 0%,#66cc66 74%);
    background: -o-radial-gradient(center, ellipse, #ffcc33 0%,#66cc66 74%);
    background: radial-gradient(ellipse at center, #ffcc33 0%,#66cc66 74%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffcc33', endColorstr='#66cc66',GradientType=1 );


}

/*********************
BOX SIZING
*********************/

/* @include box-sizing(border-box); */
/* NOTE: value of 'padding-box' is only supported in Gecko. So
probably best not to use it. I mean, were you going to anyway? */
@mixin box-sizing($type: border-box) {
    -webkit-box-sizing: $type;
    -moz-box-sizing:    $type;
    -ms-box-sizing:     $type;
    box-sizing:         $type;
}

/*********************
FLEXBOX FULL
*********************/

@mixin flexbox-container($flex-flow, $justify-content, $align-items, $important: null) {
    display: -webkit-box $important;
    display: -moz-box $important;
    display: -ms-flexbox $important;
    display: -webkit-flex $important;
    display: flex $important;
    -webkit-flex-flow: $flex-flow $important;
    -ms-flex-flow: $flex-flow $important;
    flex-flow: $flex-flow $important;
    -webkit-justify-content: $justify-content $important;
    -ms-justify-content: $justify-content $important;
    justify-content: $justify-content $important;
    -webkit-align-items: $align-items $important;
    -ms-align-items: $align-items $important;
    align-items: $align-items $important;
}

/*********************
FLEXBOX
*********************/

@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

/*********************
FLEX-FLOW
*********************/
@mixin flex-flow($values) {
    -webkit-flex-flow: $values;
    -ms-flex-flow: $values;
    flex-flow: $values;
}

/*********************
ALIGN-ITEMS
*********************/
@mixin align-items($values) {
    -webkit-align-items: $values;
    -ms-align-items: $values;
    align-items: $values;
}

/*********************
JUSTIFY-CONTENT
*********************/
@mixin justify-content($values) {
    -webkit-justify-content: $values;
    -ms-justify-content: $values;
    justify-content: $values;
}

/*********************
FLEXBOX FLEX
*********************/
@mixin flex($values) {
    -webkit-box-flex: $values;
    -moz-box-flex:  $values;
    -webkit-flex:  $values;
    -ms-flex:  $values;
    flex:  $values;
}

/*********************
FLEXBOX ORDER
*********************/
@mixin order($val) {
    -webkit-box-ordinal-group: $val;
    -moz-box-ordinal-group: $val;
    -ms-flex-order: $val;
    -webkit-order: $val;
    order: $val;
}

/*********************
FLEXBOX ALIGN-SELF
*********************/
@mixin align-self($val) {
    -webkit-align-self: $val;
    -ms-align-self: $val;
    align-self: $val;
}

/*************************
MULTILINE TEXT TRUNCATE
*************************/
@mixin multiline-ellipsis($lineheight: 1.2rem, $linecount: 1, $bgcolor: #fff, $padding-right: .8rem) {
    position: relative;
    max-height: $lineheight * $linecount;
    padding-right: $padding-right;
    line-height: $lineheight;
    text-align: justify;
    overflow: hidden;

    &:before {
        position: absolute;
        right: 0;
        bottom: 0;
        content: '...';
    }

    &:after {
        position: absolute;
        right: 0;
        width: 1rem;
        height: 1rem;
        margin-top: .2rem;
        background: $bgcolor;
        content: '';
    }
}


/*********************
ANIMATION KEYFRAMES
*********************/
@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}
