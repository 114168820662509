// main: ../main.scss
/* ======================================= */
/* COLOR VARIABLES
/* ======================================= */

$alert-yellow:         #fbcd31;
$alert-red:            #c51e4c;
$alert-green:          #8cc63c;
$alert-blue:           #00adcf;
$alert-orange:         #f05a28;

$black:                #2c2f37;
$white:                #fff;

$main:                 #c51e4c;
$aprium-blue:          #00BFF3;
$aprium-purple:        #663399;
$aprium-green:         #66cc66;
$aprium-yellow:        #ffcc33;
$aprium-orange:        #f36e21;
$aprium-pink:          #cc6699;

$gray:                 #666;
$light-gray:           #ccc;
$text-color:           #333;

$link-color:           #555658;
$link-hover:           $main;

$border-color:         #ccc;
$disabled-gray:        #cfcfcf;
