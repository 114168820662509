// main: ../main.scss
/*********************
COLOR FUNCTIONS
These are helpful when you're working
with shadows and such things. It's essentially
a quicker way to write RGBA.

Example:
box-shadow: 0 0 4px black(0.3);
compiles to:
box-shadow: 0 0 4px rgba(0,0,0,0.3);
*********************/

// black
@function black($opacity) {
    @return rgba(0, 0, 0, $opacity);
}

// white
@function white($opacity) {
    @return rgba(255, 255, 255, $opacity);
}


/*********************
RESPONSIVE HELPER FUNCTION
If you're creating a responsive site, then
you've probably already read
Responsive Web Design: http://www.abookapart.com/products/responsive-web-design

Here's a nice little helper function for calculating
target / context
as mentioned in that book.

Example:
width: cp(650px, 1000px);
or
width: calc-percent(650px, 1000px);
both compile to:
width: 65%;
*********************/

@function calc-percent($target, $container) {
    @return ($target / $container) * 100%;
}
@function cp($target, $container) {
    @return calc-percent($target, $container);
}
