/* sass-autocompile compileOnSave: true */
/*********************
IMPORTING PARTIALS
Thsese files are needed at the beginning so that we establish all
our mixins, functions, and variables that we'll be using across
the whole project.
*********************/

// import mixins
@import 'partials/mixins';

// Sass variables
@import 'partials/variables';

// typography
@import 'partials/typography';

// Sass functions
@import 'partials/functions';


/*********************
IMPORTING MODULES
Modules are reusable blocks or elements we use throughout the project.
We can break them up as much as we want or just keep them all in one.
I mean, you can do whatever yous want. The world is your oyster. Unless
you hate oysters, then the world is your peanut butter & jelly sandwich.
*********************/

// alert boxes
//@import 'modules/alerts';

// buttons
@import 'modules/buttons';

// forms (inputs & labels)
//@import 'modules/forms';

// UI ELEMENTS
@import 'modules/elements';

/*********************
BASE (MOBILE) SIZE
This are the mobile styles. It's what people see on their phones. If
you set a great foundation, you won't need to add too many styles in
the other stylesheets. Remember, keep it light: Speed is Important.
*********************/

// styles in base.scss
@import 'breakpoints/base';

/*********************
LARGER MOBILE DEVICES
This is for devices like the Galaxy Note or something that's
larger than an iPhone but smaller than a tablet. Let's call them
tweeners.
*********************/
@media only screen and (min-width: 576px) {

    // styles in 576up.scss
    @import 'breakpoints/576up';

} // end of media query


/*********************
TABLET & SMALLER LAPTOPS
This is the average viewing window. So Desktops, Laptops, and
in general anyone not viewing on a mobile device. Here's where
you can add resource intensive styles.
*********************/
@media only screen and (min-width: 768px) {

    // styles in 768up.scss
    @import 'breakpoints/768up';

} // end of media query


@media only screen and (min-width: 992px) {

    // styles in 992up.scss
    @import 'breakpoints/992up';

}

/*********************
LARGE VIEWING SIZE
This is for the larger monitors and possibly full screen viewers.
*********************/
@media only screen and (min-width: 1200px) {

    // styles in 1200up.scss
    @import 'breakpoints/1200up';

} // end of media query

/*********************
LARGE VIEWING SIZE
This is for the larger monitors and possibly full screen viewers.
*********************/
@media only screen and (min-width: 1600px) {

    // styles in 1600up.scss
    @import 'breakpoints/1600up';

} // end of media query


/*********************
RETINA (2x RESOLUTION DEVICES)
This applies to the retina iPhone (4s) and iPad (2,3) along with
other displays with a 2x resolution. You can also create a media
query for retina AND a certain size if you want. Go Nuts.
*********************/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {

        // styles in 2x.scss
        @import 'breakpoints/2x';

} // end of media query
