// main: ../main.scss

/* ============================================== */
/* WHY SECTION STYLES
/* ============================================== */
#why {
    &::after {
        left: 15%;
    }

    .before-title {
        @include order(0);
    }

    .after-title {
        @include order(2);
    }

    .logo {
        @include order(1);
    }

    #beforeList {
        @include order(3);
    }

    #afterList {
        @include order(4);
    }

    .list {
        &.before {
            li {
                margin-right: 30px;

                &::after {
                    @include transform(translateY(-50%));
                    position: absolute;
                    top: 50%;
                    right: -70px;
                    width: 40px;
                    height: 40px;
                    background: url('../images/right-arrow.png') no-repeat center center;
                    content: '';
                }
            }
        }

        &.after {
            li {
                margin-left: 30px;
            }
        }
    }
}

/* ============================================== */
/* HOW SECTION STYLES
/* ============================================== */
#how {
    .section-heading {
        top: 160px;
        font-size: 3.2rem;
    }
}

/* ============================================== */
/* TEAM SECTION STYLES
/* ============================================== */
#team {
    background: url('../images/girl-with-phone-art.png') no-repeat 90% 63%;
}
