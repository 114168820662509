// main: ../main.scss

/* ============================================ */
/* ACCORDION
/* ============================================ */
.card {
    margin-bottom: 10px;
    border: 0;
    background-color: transparent;

    .card-header {
        position: relative;
        padding: 0 0 0 30px;
        border-bottom: 2px solid $black;
        background-color: transparent;

        &::before {
            @include transform(translateY(-50%));
            position: absolute;
            top: 50%;
            left: 0;
            width: 22px;
            height: 22px;
            background: url('../images/icon-plus-circle.svg') no-repeat center;
            content: '';
        }

        &.collapsed {
            &::before {
                background: url('../images/icon-minus-circle.svg') no-repeat center;
            }
        }

        h5 {
            @include flexbox-container(row nowrap, space-between, center);
        }

        button {
            display: block;
            width: 100%;
            padding: 0;
            color: $black;
            font-size: 1.6rem;
            font-weight: 700;
            text-decoration: none;
            text-align: left;
            white-space: normal;
        }
    }
}
