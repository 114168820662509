// main: ../main.scss
/* ============================================== */
/* MAIN HEADER
/* ============================================== */

#mainHeader {
    #mobileLogo {
        display: none;
    }

    #desktopLogo {
        display: block;
    }
}

/* ============================================== */
/* CONTACT SECTION STYLES
/* ============================================== */
#contact {
    form {
        #optionList {
            li {
                padding: 10px;
            }
        }
    }
}


/* ============================================== */
/* FOOTER STYLES
/* ============================================== */
#mainFooter {
    .footer-nav {
        @include flexbox-container(row nowrap, center, center);

        .nav-item {
            display: unset;
            padding: 5px 10px;

            &.separator {
                display: unset;
                width: 4px;
                height: 1px;
                // margin: 0 5px;
                padding: 0;
                background-color: $light-gray;
            }
        }
    }
}
