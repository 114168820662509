// main: ../main.scss

/* ============================================== */
/* BASE
/* ============================================== */
html {
    font-size: 14px;
}

body {
    font-family: $lato !important;
    font-weight: 400;
}

.yellow-color {
    color: $aprium-yellow;
}

.custom-button {
    margin-top: 10px;
}

/* ============================================== */
/* MAIN HEADER
/* ============================================== */

#mainHeader {
    border-radius: 0 0 7px 7px;
    background-color: $black;
    box-shadow: 0 3px 6px 0 rgba($black, .3);

    .navbar-brand {
        margin-right: auto;
    }

    #mobileLogo {
        display: block;
    }

    #desktopLogo {
        display: none;
    }

    .nav-link {
        color: $light-gray;
        text-transform: uppercase;
    }

    #signInBtn {
        position: relative;
        padding-right: 20px;
        color: $light-gray;
        text-transform: uppercase;
        font-size: 1rem;

        .btn-icon {
            position: absolute;
            top: 2px;
            right: 0;
            display: inline-block;
            width: 15px;
            height: 25px;
            margin-left: 3px;
            background: url('../images/person-icon.png') no-repeat center center;
        }
    }
}

/* ============================================== */
/* MAIN CONTENT
/* ============================================== */
.web-section {
    padding: 90px 0;

    &.with-pointer {
        position: relative;

        &::after {
            @include transform(translateX(-50%));
            position: absolute;
            bottom: -118px;
            left: 50%;
            border: 60px solid transparent;
            border-top: 60px solid $white;
            content: '';
            z-index: 99;
        }
    }

    .section-heading {
        font-size: $h1-plus;
        font-weight: 900;
        text-transform: inherit;
    }
}

/* ============================================== */
/* WHAT SECTION STYLES
/* ============================================== */
#what {
    padding: 60px 0;

    &::after {
        left: 56%;
    }
}

/* ============================================== */
/* WHY SECTION STYLES
/* ============================================== */
#why {
    @include css-linear-gradient(top, $aprium-purple, 0%, $aprium-blue, 100%);

    &::after {
        left: 30%;
        border-top-color: $aprium-blue;
    }

    .before-title {
        @include order(0);
    }

    .after-title {
        @include order(3);
    }

    .title {
        margin-bottom: 0;
        font-size: 2.4rem;
        color: $white;
        text-transform: uppercase;
    }

    .logo {
        @include order(1);

        img {
            max-width: 350px;
        }
    }

    #beforeList {
        @include order(2);
    }

    #afterList {
        @include order(4);
    }

    .list {
        margin-top: 20px;
        padding: 0;
        list-style: none;

        li {
            position: relative;
            display: block;
            margin: 15px 0;
            padding: 10px 15px;
            border: 2px solid $white;
            border-radius: 5px;
            color: $white;
            font-size: $h2;
            font-weight: 700;
            line-height: 1.2;

            span {
                display: inline-block;
                line-height: 2.2;
            }
        }
    }
}

/* ============================================== */
/* HOW SECTION STYLES
/* ============================================== */
#how {
    &::after {
        left: 63%;
    }

    .section-heading {
        br {
            display: none;
        }
    }
}

/* ============================================== */
/* FAQ SECTION STYLES
/* ============================================== */
#faq {
    @include css-linear-gradient(top, $aprium-yellow, 0%, $aprium-green, 74%);

    &::after {
        left: 27%;
        border-top-color: $aprium-green;
    }

    #title {
        @include order(0);
    }

    #accordions {
        @include order(1);
    }

    .section-heading {
        font-size: $h1-plus;

        br {
            display: none;
        }
    }
}

/* ============================================== */
/* TEAM SECTION STYLES
/* ============================================== */
#team {
    &::after {
        left: 50%;
    }

    .team-section {
        margin-bottom: 20px;
        padding-left: 20px;

        .team-section-title {
            margin-left: -20px;
        }

        .team-section-text {
            font-size: 1.15rem;
            line-height: 1.3;
        }
    }
}

/* ============================================== */
/* CONTACT SECTION STYLES
/* ============================================== */
#contact {
    @include css-linear-gradient(top, $aprium-orange, 0%, $aprium-pink, 100%);
    padding-bottom: 60px;

    .section-heading {
        color: $white;
    }

    form {
        label {
            color: $white;
        }

        #optionList {
            @include flexbox-container(row wrap, space-between, center);
            margin: 0 -2px;
            padding-left: 0;
            list-style: none;

            li {
                @include flex(1 1 48%);
                @include transition(all .25s ease);
                max-width: 48%;
                margin: 2px;
                padding: 5px;
                border: 1px solid $gray;
                border-radius: 5px;
                background-color: $white;
                color: $text-color;

                &:active, &:hover, &.active {
                    background-color: $aprium-pink;
                    color: $white;
                }
            }
        }

        .form-group {
            margin-bottom: 3px;

            input, textarea {
                border: 1px solid $gray;
                border-radius: 5px;
            }
        }

        button[type="submit"] {
            display: block;
            width: 100%;
            margin-top: 15px;
            color: $white;
            box-shadow: 0 5px 8px 0 rgba($black, .3);
        }
    }
}


/* ============================================== */
/* FOOTER STYLES
/* ============================================== */
#mainFooter {
    padding: 60px 0;
    background-color: $black;

    .footer-nav {
        text-align: center;

        .nav-item {
            @include transition(all .25s ease);
            display: block;
            padding: 3px;
            color: $light-gray;

            &:hover {
                color: $white;
                text-decoration: none;
            }

            &.separator {
                display: none;
            }
        }

        #copy, .separator {
            &:hover, &:active, &:focus {
                color: $light-gray;
            }
        }
    }
}
