// main: ../main.scss
/* ============================================== */
/* MAIN HEADER
/* ============================================== */

#mainHeader {
    .navbar-brand {
        margin-right: 0;
    }

    #signInBtn {
        @include order(10);
    }
}

/* ============================================== */
/* HOW SECTION STYLES
/* ============================================== */
#how {
    padding: 0 0 60px 0;

    .container {
        position: relative;
    }

    .section-heading {
        position: absolute;
        top: 140px;
        font-size: 2.6rem;

        br {
            display: unset;
        }
    }

    #treeArt {
        margin-top: -60px;
    }
}


/* ============================================== */
/* FAQ SECTION STYLES
/* ============================================== */
#faq {
    #title {
        @include order(1);
    }

    #accordions {
        @include order(0);
    }

    .section-heading {
        br {
            display: unset;
        }
    }

}

/* ============================================== */
/* TEAM SECTION STYLES
/* ============================================== */
#team {
    padding: 100px 0 160px 0;
    background: url('../images/girl-with-phone-art.png') no-repeat 90% 83%;
}
