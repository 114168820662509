// main: ../main.scss
/*********************
BUTTON DEFAULTS
We're gonna use a placeholder selector here
so we can use common styles. We then use this
to load up the defaults in all our buttons.

Here's a quick video to show how it works:
http://www.youtube.com/watch?v=hwdVpKiJzac

*********************/
%btn {
    @include transition(all .14s ease-in-out);
    display: inline-block;
    padding: 10px 15px;
    border: 0;
    color: $text-color;
    font-size: $small-text;
    font-weight: 400;
    line-height: 100%;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;

    &.small {
        padding: 5px 10px;
        font-size: $regular-text;
    }

    &.medium {
        padding: 8px 15px;
        font-size: $h3;
    }

    &.large {
        padding: 10px 20px;
        font-size: $h2;
    }

    // hovering on the btn
    &:hover, &:focus, &:active {
        outline: none;
        opacity: .8;
        text-decoration: none;
    }
    // end hover

    // clicking on the button
    &:active {
        top: 1px; // adds a tiny hop to the click
    }
    // end active

    &:disabled {
        opacity: .6;
    }
}

.custom-button {
    @extend %btn;
    // position: relative;
    background-color: $aprium-yellow;
    box-shadow: 3px 5px 8px 0 rgba($black, .3);
    font-weight: 700;

    // &::after {
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     border: 10px solid transparent;
    //     border-left-color: $black;
    //     content: '';
    // }
}

.button {
    @extend %btn;
    border-radius: 5px;
    background-color: $aprium-yellow;
    font-weight: 700;
}
